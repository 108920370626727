<template>
  <PageDefault :isLoading="isLoading">
    <template v-slot:content>
      <section v-if="isLoading" class="mt-4 has-text-centered">
        <div class="card">
          <div class="card-content">
            <p>Cargando...</p>
          </div>
        </div>
      </section>

      <section
        class="order-success-header"
        v-if="!isLoading && order.status == 'finished'"
      >
        <div class="message">
          <p class="is-size-4" align="center" translate>
            Tu compra ha sido realizada con éxito
          </p>
          <div class="ref">
            <label class="label">
              <translate>Referencia de operación</translate>
              :
              <span class="reference">{{ order.lookup_ref }}</span>
            </label>
          </div>
          <p>
            <translate
              class="ma-0"
              :translate-n="numTickets"
              translate-plural="Para acceder al recinto es necesario llevar los códigos QR en tu dispositivo móvil o bien imprimir las entradas que te hemos enviado a la dirección de email:"
            >
              Para acceder al recinto es necesario llevar el código QR en tu dispositivo
              móvil o bien imprimir la entrada que te hemos enviado a la dirección de
              email:
            </translate>
            <span class="user-email">{{ order.email }}.</span>
          </p>
          <translate>Revisa tu buzón de correo.</translate>
        </div>
        <MultipassOrderSummary class="order-summary" :order="order" />
      </section>
    </template>
  </PageDefault>
</template>

<script>
import MultipassOrderSummary from "@/components/multipass/MultipassOrderSummary"

export default {
  name: "MultipassOrderSuccess",

  beforeMount() {
    if (!this.$route.params.lookup_ref)
      this.$router.replace({ name: "404", query: this.$defaultQuery() })
    this.getOrder()
  },
  components: {
    MultipassOrderSummary,
  },
  computed: {
    numTickets() {
      return (this.order && this.order.tickets.length) || 0
    },
  },
  data() {
    return {
      order: null,
      isLoading: false,
    }
  },
  methods: {
    getOrder() {
      this.order = null
      this.isLoading = true
      this.$api.getMultipassOrder(this.$route.params.lookup_ref).then((response) => {
        this.order = response
        this.isLoading = false
        if (this.order.status != "finished") {
          this.$router.push({
            name: "multipass-order-error",
            params: { lookup_ref: this.order.lookup_ref },
            query: this.$defaultQuery(),
          })
        }
      })
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
@import '@/assets/sass/_responsive.sass'

.order-success-header
  .is-size-4
    font-weight: $weight-semibold
  .label
    color: darken($success, 33%)
  .reference
    font-weight: $weight-semibold
  .user-email
    color: darken($success, 33%)
    font-weight: $weight-semibold
    font-size: 1.12rem
  @include from($tablet)
    margin: 3rem 0
  @include until($tablet)
    margin: 1.5rem 0

.message
  background: $success
  color: darken($success, 33%)
  padding: 1rem

.download-section
  @include from($mobile)
    margin: 2rem 0
    display: flex
    justify-content: space-between
    align-items: flex-start
  @include until($mobile)
    margin: 1rem 0

  .download-button
    @include until($mobile)
      width: 100%
      margin: 1rem 0 2rem 0
</style>
