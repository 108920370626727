<template>
  <div
    v-if="zone && seat"
    class="modal"
    :class="{ 'is-active': seat.has_notice }"
    scroll="keep"
    @click="$emit('close')"
  >
    <div class="modal-background"></div>
    <div class="modal-content seat-dialog">
      <div class="modal-card-body">
        <div class="continer-title mt-2">
          <span :style="`color: ${zone.color}`" class="fa fa-lg fa-circle mr-2"></span>
          <h1 class="title has-text-weight-light">
            {{ zone.name }} |
            {{ zone.rows_name }}
            {{ seat.row }} |
            <translate>Butaca</translate>
            {{ seat.name }}
          </h1>
        </div>
        <div class="title-message-modal mt-4 has-text-weight-bold">
          {{ seat.notice_header }}
        </div>
        <div class="message-modal has-text-weight-normal mt-1">
          {{ seat.notice_description }}
        </div>
        <div class="my-2 container-buttons" v-if="isSelectable">
          <b-button class="secondary-button button" @click="$emit('close')">
            <translate>Cancelar</translate>
          </b-button>
          <b-button class="secondary-button button" @click="$emit('select')">
            <translate>Continuar</translate>
          </b-button>
        </div>
        <div class="my-2 container-buttons" v-if="!isSelectable">
          <b-button class="secondary-button button" @click="$emit('close')">
            <translate>Continuar</translate>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmSeatDialog",
  props: {
    seat: Object,
    zone: Object,
    isSelectable: Boolean,
  },
}
</script>

<style lang="sass">
@import '@/assets/sass/_responsive.sass'
@import '@/assets/sass/_variables.sass'


.modal-content.seat-dialog
  max-width: 600px
  .modal-card-body
    background-color: #fff
    border-top: 10px solid $primary
    padding-top: 20px
    padding-bottom: 20px
    .message-modal
      width: auto
      white-space: normal
    .continer-title
      display: flex
      align-items: center
    .title
      font-size: 18px
    .title-message-modal, .message-modal
      margin-left: 2.5rem
      font-size: 14px
      color: #000
    .container-buttons
      display: flex
      justify-content: center
      align-items: center
      .secondary-button
        text-transform: uppercase
        padding-top: .5rem
        padding-bottom: .5rem
.modal
  +until($tablet)
    .modal-content
        margin: auto
        max-width: 600px
        position: absolute
        max-height: auto
        z-index: 999999
        top: 0
        .modal-card-body
          height: auto
        .message-modal
          width: auto
          white-space: normal
    .title
      font-size: 16px !important
    .title-message-modal, .message-modal
      margin-right: 2.5rem
</style>
