





















































































































































import filters from "@/filters"
import _ from "lodash"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
import ConfirmSeatDialog from "@/components/ConfirmSeatDialog.vue"

@Component({
  components: { ConfirmSeatDialog },
  filters: filters,
})
export default class MultipassOrderSummary extends Vue {
  visibleDetails: boolean = true
  @Prop({
    type: Object,
    required: true,
    default: {},
  })
  order?: Dictionary<any>

  // SeatNoticeModal
  seatWithNotice: any = null
  noticeZone: any = null

  displaySeatNotice(ticket: any) {
    this.seatWithNotice = ticket.seat
    this.noticeZone = {
      name: ticket.seat.zone_name,
      color: ticket.seat.color,
      rows_name: ticket.seat.zone_rows_name,
    }
  }

  closeNoticeDialog() {
    this.seatWithNotice = null
    this.noticeZone = null
  }

  toggleDetails() {
    this.visibleDetails = !this.visibleDetails
  }

  ticketSummaryFillAttr(line: any) {
    return _.get(line, "color", null)
  }

  get numTickets(): number {
    return this.order?.tickets.length || 0
  }

  get hasReducedMobility() {
    return _.some(
      this.order?.tickets,
      (ticket: any) => ticket.seat.representation == "reduced_mobility"
    )
  }

  getTaxTotal(taxDistribution: any) {
    return parseFloat(taxDistribution.tax_amount) + parseFloat(taxDistribution.tax_base)
  }
  getTaxBase(totalAmount: string, taxDistribution: any) {
    return parseFloat(totalAmount) - parseFloat(taxDistribution.tax_amount)
  }
}
